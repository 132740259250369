import React from "react"
import ReactPlayer from "react-player/youtube"
import { VideoConfig } from "../../config/config-data"


const Video = ({ GradientAnimation }) => (

  <section id={VideoConfig.section_header}>
    {VideoConfig.section_header &&
    <div id="video_1670588711054" className={`Heading_Text ${!GradientAnimation ? "Heading_Gradient" : ""}`}>{VideoConfig.section_header}</div>
    }
    {VideoConfig.section_sub_header &&
    <div id="video_1670588715534" className="Normal_Text" style={{paddingBottom:"var(--Text_Size_Five)"}}>{VideoConfig.section_sub_header}</div>
    }
    <ReactPlayer
      width={false}
      height={false}
      loop={VideoConfig.video_loop && true}
      muted={VideoConfig.video_mute && true}
      controls={VideoConfig.video_controls && true}
      url={VideoConfig.video_url}
      light={VideoConfig.video_thumbnail || true}
      className="Video_Player"
    />
  </section>
);

export default Video